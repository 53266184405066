import axios from 'axios'
import { VueAxios } from './axios'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: API_BASE_URL,
  timeout: 60 * 60 * 10 * 1000 // 请求超时时间
})

// 正常响应拦截处理器
const successHandler = (response) => {
  // 流数据
  if (response.data instanceof ArrayBuffer) {
    return Promise.resolve(response)
  }
  // 非0
  if (response.data.code !== 0) {
    return Promise.reject(response.data)
  }

  return response.data
}

// 响应拦截器
request.interceptors.response.use(successHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
