import Vue from 'vue'
import App from './App.vue'
import '@/assets/style/global.less'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia)

Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  render: h => h(App)
}).$mount('#app')
