/**
 * 首页相关API
 */
import request from '@/utils/request'

const api = {
  index: '/home/index'
}

// 获取首页数据
export function getIndexData (params) {
  return request({
    url: api.index,
    method: 'get',
    params
  })
}
