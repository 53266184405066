import Vue from 'vue'
import Router from 'vue-router'
import { useMainStore } from '@/store'

Vue.use(Router)

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/home/index')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/home/privacy')
  },
  {
    path: '/term-service',
    name: 'termService',
    component: () => import('@/views/home/termService')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
  const store = useMainStore()
  store.getSiteConfig()

  // console.log('beforeEach')
  // setTimeout(() => {
  //   const store = useMainStore()
  //   store.getSiteConfig()
  // })
  next()
})

export default router
