import { defineStore } from 'pinia'
import { getIndexData } from '@/api/home'

export const useMainStore = defineStore('mainStore', {
  state: () => ({
    mmsUrl: null,
    bannerInfo: null,
    appQrcodeUrl: null,
    siteCopyright: null
  }),
  actions: {
    async getSiteConfig () {
      const res = await getIndexData()
      if (res && res.data) {
        const { siteConfig, bannerInfo } = res.data
        if (siteConfig) {
          const { appQrcodeUrl, siteCopyright, mmsUrl } = siteConfig
          this.mmsUrl = mmsUrl
          this.appQrcodeUrl = appQrcodeUrl
          this.siteCopyright = siteCopyright
        }
        if (bannerInfo) {
          this.bannerInfo = bannerInfo
        }
      } else {
        throw new Error('获取网站配置信息失败')
      }
    }
  }
})
